@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-backgroundColor text-primaryBlack;
  }

  a:hover {
    @apply text-accentGreen transition ease-in-out sm:text-primaryBlack;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

*::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
*::-webkit-scrollbar-track {
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: #d4d8e2;
}
*::-webkit-scrollbar-thumb:window-inactive {
  background: #d4d8e2;
}

html,
body,
:root {
  height: 100%;
}

input:checked + div {
  @apply bg-accentGreen;
}
input:checked + div svg {
  @apply block;
}

.html-description p {
  @apply my-4;
}

.html-description a {
  color: #00cc8f;
}

.html-description * {
  background-color: transparent !important;
  /* font-family: 'Onest' !important; */
  font-size: 16px !important;
}

.html-description ol {
  list-style: decimal;
  padding-left: 24px;
}

.favoritesButton:hover .filledSvg {
  fill: currentColor;
  stroke: currentColor;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  height: 500px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  height: 500px;
  object-fit: cover;
}

@media screen and (max-width: 639px) {
  .swiper-slide {
    height: 200px !important;
  }

  .swiper-slide img {
    height: 200px;
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  position: inherit !important;
  width: max-content !important;
  height: max-content;
}

.swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: #061213 !important;
  width: 8px !important;
  height: 8px !important;
}

.swiper-pagination-bullet-active {
  background-color: #00cc8f !important;
  width: 12px !important;
  height: 12px !important;
}

.store-map-button {
  clip-path: path(
    'M0 54.5997C0 68.3889 11.5924 79.3423 25.3595 78.5612L259.359 65.2846C272.066 64.5636 282 54.0498 282 41.3231V24C282 10.7452 271.255 0 258 0H24C10.7452 0 0 10.7452 0 24V54.5997Z'
  );
}

.ask-question-button {
  clip-path: path(
    'M281 24.4003C281 11.1856 269.891 0.688668 256.697 1.43723L22.6972 14.7138C10.5203 15.4047 1 25.4805 1 37.6769V55C1 67.7026 11.2975 78 24 78H258C270.703 78 281 67.7025 281 55V24.4003Z'
  );
}

.tile-polygon-0 {
  width: 384px;
  height: 314.414px;
  clip-path: polygon(
    0px 290.859px,
    0px 290.859px,
    0.4006525px 295.266248px,
    1.551656px 299.385424px,
    3.3765795px 303.153576px,
    5.798992px 306.507752px,
    8.7424625px 309.385px,
    12.13056px 311.722368px,
    15.8868535px 313.456904px,
    19.934912px 314.525656px,
    24.1983045px 314.865672px,
    28.6006px 314.414px,
    calc(100% - 19.399px) calc(100% - 65.625px),
    calc(100% - 19.399px) calc(100% - 65.625px),
    calc(100% - 16.117704px) calc(100% - 66.509396px),
    calc(100% - 13.054592px) calc(100% - 67.817728px),
    calc(100% - 10.239628px) calc(100% - 69.513612px),
    calc(100% - 7.702776px) calc(100% - 71.560664px),
    calc(100% - 5.474px) calc(100% - 73.9225px),
    calc(100% - 3.583264px) calc(100% - 76.562736px),
    calc(100% - 2.060532px) calc(100% - 79.444988px),
    calc(100% - 0.935768px) calc(100% - 82.532872px),
    calc(100% - 0.23893600000002px) calc(100% - 85.790004px),
    calc(100% - 5.6843418860808e-14px) calc(100% - 89.18px),
    calc(100% - 0px) 24px,
    calc(100% - 0px) 24px,
    calc(100% - 0.31411499999996px) 20.1070836px,
    calc(100% - 1.22352px) 16.4141568px,
    calc(100% - 2.678805px) 12.9706332px,
    calc(100% - 4.63056px) 9.8259264px,
    calc(100% - 7.029375px) 7.02945px,
    calc(100% - 9.82584px) 4.6306176px,
    calc(100% - 12.970545px) 2.6788428px,
    calc(100% - 16.41408px) 1.2235392px,
    calc(100% - 20.107035px) 0.3141204px,
    calc(100% - 24px) 3.9733444681785e-31px,
    24px 0px,
    24px 0px,
    20.1070836px 0.3141204px,
    16.4141568px 1.2235392px,
    12.9706332px 2.6788428px,
    9.8259264px 4.6306176px,
    7.02945px 7.02945px,
    4.6306176px 9.8259264px,
    2.6788428px 12.9706332px,
    1.2235392px 16.4141568px,
    0.3141204px 20.1070836px,
    3.9733444681785e-31px 24px,
    0px 290.859px
  );
}

.tile-polygon-1 {
  border-radius: 24px;
  height: 249px;
  margin: 0 16px;
}

.tile-polygon-2 {
  width: 384px;
  height: 314.414px;
  clip-path: polygon(
    calc(100% - 0px) calc(100% - 23.555px),
    calc(100% - 0px) calc(100% - 23.555px),
    calc(100% - 0.40066099999996px) calc(100% - 19.147752px),
    calc(100% - 1.551688px) calc(100% - 15.028576px),
    calc(100% - 3.376647px) calc(100% - 11.260424px),
    calc(100% - 5.7991039999999px) calc(100% - 7.906248px),
    calc(100% - 8.742625px) calc(100% - 5.029px),
    calc(100% - 12.130776px) calc(100% - 2.691632px),
    calc(100% - 15.887123px) calc(100% - 0.95709599999998px),
    calc(100% - 19.935232px) calc(100% - -0.11165600000004px),
    calc(100% - 24.198669px) calc(100% - -0.45167200000003px),
    calc(100% - 28.601px) calc(100% - 5.6843418860808e-14px),
    19.3994px 248.789px,
    19.3994px 248.789px,
    16.11806364px 247.904604px,
    13.05490432px 246.596272px,
    10.23988868px 244.900388px,
    7.70298336px 242.853336px,
    5.474155px 240.4915px,
    3.58337024px 237.851264px,
    2.06059572px 234.969012px,
    0.93579808px 231.881128px,
    0.23894396px 228.623996px,
    3.0067729225338e-31px 225.234px,
    0px 24px,
    0px 24px,
    0.3141204px 20.1070836px,
    1.2235392px 16.4141568px,
    2.6788428px 12.9706332px,
    4.6306176px 9.8259264px,
    7.02945px 7.02945px,
    9.8259264px 4.6306176px,
    12.9706332px 2.6788428px,
    16.4141568px 1.2235392px,
    20.1070836px 0.3141204px,
    24px 3.9733444681785e-31px,
    calc(100% - 24px) 0px,
    calc(100% - 24px) 0px,
    calc(100% - 20.107035px) 0.3141204px,
    calc(100% - 16.41408px) 1.2235392px,
    calc(100% - 12.970545px) 2.6788428px,
    calc(100% - 9.82584px) 4.6306176px,
    calc(100% - 7.029375px) 7.02945px,
    calc(100% - 4.6305600000001px) 9.8259264px,
    calc(100% - 2.678805px) 12.9706332px,
    calc(100% - 1.22352px) 16.4141568px,
    calc(100% - 0.31411500000002px) 20.1070836px,
    calc(100% - 5.6843418860808e-14px) 24px,
    calc(100% - 0px) calc(100% - 23.555px)
  );
}

.tile-polygon-3 {
  width: 384px;
  height: 315px;
  bottom: 15px;
  clip-path: polygon(
    calc(100% - 0px) 44.141px,
    calc(100% - 0px) 44.141px,
    calc(100% - 0.40066099999996px) 39.7337601px,
    calc(100% - 1.551688px) 35.6146048px,
    calc(100% - 3.376647px) 31.8464807px,
    calc(100% - 5.7991039999999px) 28.4923344px,
    calc(100% - 8.742625px) 25.6151125px,
    calc(100% - 12.130776px) 23.2777616px,
    calc(100% - 15.887123px) 21.5432283px,
    calc(100% - 19.935232px) 20.4744592px,
    calc(100% - 24.198669px) 20.1344009px,
    calc(100% - 28.601px) 20.586px,
    19.3994px 86.2111px,
    19.3994px 86.2111px,
    16.11806607px 87.0954419px,
    13.05490816px 88.4037312px,
    10.23989309px 90.0995833px,
    7.70298768px 92.1466136px,
    5.47415875px 94.5084375px,
    3.58337312px 97.1486704px,
    2.06059761px 100.0309277px,
    0.93579904px 103.1188248px,
    0.23894423px 106.3759771px,
    3.0067766203193e-31px 109.766px,
    0px 291px,
    0px 291px,
    0.3141204px 294.892965px,
    1.2235392px 298.58592px,
    2.6788428px 302.029455px,
    4.6306176px 305.17416px,
    7.02945px 307.970625px,
    9.8259264px 310.36944px,
    12.9706332px 312.321195px,
    16.4141568px 313.77648px,
    20.1070836px 314.685885px,
    24px 315px,
    calc(100% - 24px) calc(100% - 0px),
    calc(100% - 24px) calc(100% - 0px),
    calc(100% - 20.107035px) calc(100% - 0.31411500000002px),
    calc(100% - 16.41408px) calc(100% - 1.22352px),
    calc(100% - 12.970545px) calc(100% - 2.678805px),
    calc(100% - 9.82584px) calc(100% - 4.6305599999999px),
    calc(100% - 7.029375px) calc(100% - 7.029375px),
    calc(100% - 4.6305600000001px) calc(100% - 9.82584px),
    calc(100% - 2.678805px) calc(100% - 12.970545px),
    calc(100% - 1.22352px) calc(100% - 16.41408px),
    calc(100% - 0.31411500000002px) calc(100% - 20.107035px),
    calc(100% - 5.6843418860808e-14px) calc(100% - 24px),
    calc(100% - 0px) 44.141px
  );
}

.tile-polygon-4 {
  border-radius: 24px;
  height: 300px;
  margin: 0 16px;
}

.flipped {
  transform: scaleX(1);
}

.tile-polygon-5 {
  width: 384px;
  height: 300px;
  clip-path: polygon(
    0px 29.141px,
    0px 29.141px,
    0.4006525px 24.73376123px,
    1.551656px 20.61460904px,
    3.3765795px 16.84648961px,
    5.798992px 13.49234912px,
    8.7424625px 10.61513375px,
    12.13056px 8.27778968px,
    15.8868535px 6.54326309px,
    19.934912px 5.47450016px,
    24.1983045px 5.13444707px,
    28.6006px 5.58605px,
    calc(100% - 19.399px) 71.2111px,
    calc(100% - 19.399px) 71.2111px,
    calc(100% - 16.117704px) 72.0954419px,
    calc(100% - 13.054592px) 73.4037312px,
    calc(100% - 10.239628px) 75.0995833px,
    calc(100% - 7.702776px) 77.1466136px,
    calc(100% - 5.474px) 79.5084375px,
    calc(100% - 3.583264px) 82.1486704px,
    calc(100% - 2.060532px) 85.0309277px,
    calc(100% - 0.935768px) 88.1188248px,
    calc(100% - 0.23893600000002px) 91.3759771px,
    calc(100% - 5.6843418860808e-14px) 94.766px,
    calc(100% - 0px) calc(100% - 24px),
    calc(100% - 0px) calc(100% - 24px),
    calc(100% - 0.31411499999996px) calc(100% - 20.107035px),
    calc(100% - 1.22352px) calc(100% - 16.41408px),
    calc(100% - 2.678805px) calc(100% - 12.970545px),
    calc(100% - 4.63056px) calc(100% - 9.82584px),
    calc(100% - 7.029375px) calc(100% - 7.029375px),
    calc(100% - 9.82584px) calc(100% - 4.63056px),
    calc(100% - 12.970545px) calc(100% - 2.678805px),
    calc(100% - 16.41408px) calc(100% - 1.22352px),
    calc(100% - 20.107035px) calc(100% - 0.31411499999996px),
    calc(100% - 24px) calc(100% - 5.6843418860808e-14px),
    24px 300px,
    24px 300px,
    20.1070836px 299.685885px,
    16.4141568px 298.77648px,
    12.9706332px 297.321195px,
    9.8259264px 295.36944px,
    7.02945px 292.970625px,
    4.6306176px 290.17416px,
    2.6788428px 287.029455px,
    1.2235392px 283.58592px,
    0.3141204px 279.892965px,
    3.9733444681785e-31px 276px,
    0px 29.141px
  );
}

.tile-polygon-6 {
  border-radius: 24px;
  height: 228px;
  min-width: 384px;
}

.tile-polygon-7 {
  border-radius: 24px;
  height: 228px;
  width: 100%;
  max-width: 792px;
  margin-left: 16px;
}

.home-green-clip {
  height: 239.002px;
  clip-path: polygon(
    0px 215.021px,
    0px 215.021px,
    0.3311122px 219.019489px,
    1.2882176px 222.801552px,
    2.8169694px 226.314983px,
    4.8630208px 229.507576px,
    7.372025px 232.327125px,
    10.2896352px 234.721424px,
    13.5615046px 236.638267px,
    17.1332864px 238.025448px,
    20.9506338px 238.830761px,
    24.9592px 239.002px,
    calc(100% - 23.04px) calc(100% - 46.08px),
    calc(100% - 23.04px) calc(100% - 46.08px),
    calc(100% - 19.26747px) calc(100% - 46.528826px),
    calc(100% - 15.70176px) calc(100% - 47.535208px),
    calc(100% - 12.38769px) calc(100% - 49.052502px),
    calc(100% - 9.3700799999999px) calc(100% - 51.034064px),
    calc(100% - 6.6937499999999px) calc(100% - 53.43325px),
    calc(100% - 4.4035200000001px) calc(100% - 56.203416px),
    calc(100% - 2.54421px) calc(100% - 59.297918px),
    calc(100% - 1.1606400000001px) calc(100% - 62.670112px),
    calc(100% - 0.2976299999998px) calc(100% - 66.273354px),
    calc(100% - 2.2737367544323e-13px) calc(100% - 70.061px),
    calc(100% - 0px) 24px,
    calc(100% - 0px) 24px,
    calc(100% - 0.3142499999999px) 20.1070836px,
    calc(100% - 1.2239999999997px) 16.4141568px,
    calc(100% - 2.67975px) 12.9706332px,
    calc(100% - 4.6320000000001px) 9.8259264px,
    calc(100% - 7.03125px) 7.02945px,
    calc(100% - 9.8279999999997px) 4.6306176px,
    calc(100% - 12.97275px) 2.6788428px,
    calc(100% - 16.416px) 1.2235392px,
    calc(100% - 20.10825px) 0.3141204px,
    calc(100% - 24px) 3.9733444681785e-31px,
    24px 0px,
    24px 0px,
    20.1070593px 0.3141204px,
    16.4141184px 1.2235392px,
    12.9705891px 2.6788428px,
    9.8258832px 4.6306176px,
    7.0294125px 7.02945px,
    4.6305888px 9.8259264px,
    2.6788239px 12.9706332px,
    1.2235296px 16.4141568px,
    0.3141177px 20.1070836px,
    3.9733074903236e-31px 24px,
    0px 215.021px
  );
}

.home-white-clip {
  clip-path: polygon(
    calc(100% - 0px) 24.9792px,
    calc(100% - 0px) 24.9792px,
    calc(100% - 0.33113999999978px) 20.980669616px,
    calc(100% - 1.2883199999997px) 17.198549608px,
    calc(100% - 2.81718px) 13.685056692px,
    calc(100% - 4.8633600000001px) 10.492407584px,
    calc(100% - 7.3724999999999px) 7.672819px,
    calc(100% - 10.29024px) 5.278507656px,
    calc(100% - 13.56222px) 3.361690268px,
    calc(100% - 17.13408px) 1.974583552px,
    calc(100% - 20.95146px) 1.169404224px,
    calc(100% - 24.96px) 0.998369px,
    23.0408px 47.0784px,
    23.0408px 47.0784px,
    19.2679074px 47.5271529px,
    15.7019392px 48.5334432px,
    12.3876998px 50.0506383px,
    9.3699936px 52.0321056px,
    6.693625px 54.4312125px,
    4.4033984px 57.2013264px,
    2.5441182px 60.2958147px,
    1.1605888px 63.6680448px,
    0.2976146px 67.2713841px,
    3.7604259794787e-31px 71.0592px,
    0px calc(100% - 24px),
    0px calc(100% - 24px),
    0.3141177px calc(100% - 20.107035px),
    1.2235296px calc(100% - 16.41408px),
    2.6788239px calc(100% - 12.970545px),
    4.6305888px calc(100% - 9.82584px),
    7.0294125px calc(100% - 7.029375px),
    9.8258832px calc(100% - 4.63056px),
    12.9705891px calc(100% - 2.678805px),
    16.4141184px calc(100% - 1.22352px),
    20.1070593px calc(100% - 0.31411500000002px),
    24px calc(100% - 5.6843418860808e-14px),
    calc(100% - 24px) calc(100% - 0px),
    calc(100% - 24px) calc(100% - 0px),
    calc(100% - 20.10825px) calc(100% - 0.31411499999996px),
    calc(100% - 16.416px) calc(100% - 1.2235199999999px),
    calc(100% - 12.97275px) calc(100% - 2.678805px),
    calc(100% - 9.8280000000002px) calc(100% - 4.63056px),
    calc(100% - 7.03125px) calc(100% - 7.029375px),
    calc(100% - 4.6319999999998px) calc(100% - 9.82584px),
    calc(100% - 2.67975px) calc(100% - 12.970545px),
    calc(100% - 1.2239999999999px) calc(100% - 16.41408px),
    calc(100% - 0.3142499999999px) calc(100% - 20.107035px),
    calc(100% - 2.2737367544323e-13px) calc(100% - 24px),
    calc(100% - 0px) 24.9792px
  );
}

.footer-map-clip {
  height: 317.751px;
  clip-path: polygon(
    0.5px 24px,
    0.5px 24px,
    0.8075751px 20.188201292px,
    1.6980448px 16.572221656px,
    3.1230257px 13.200445824px,
    5.0341344px 10.121258528px,
    7.3829875px 7.3830445px,
    10.1212016px 5.034188472px,
    13.2003933px 3.123075176px,
    16.5721792px 1.698089344px,
    20.1881759px 0.807615708px,
    24px 0.500039px,
    calc(100% - 23.5px) 0.500001px,
    calc(100% - 23.5px) 0.500001px,
    calc(100% - 19.688103px) 0.807576072px,
    calc(100% - 16.072064px) 1.698045696px,
    calc(100% - 12.700261px) 3.123026484px,
    calc(100% - 9.6210719999999px) 5.034135048px,
    calc(100% - 6.882875px) 7.382988px,
    calc(100% - 4.534048px) 10.121201952px,
    calc(100% - 2.622969px) 13.200393516px,
    calc(100% - 1.1980160000001px) 16.572179304px,
    calc(100% - 0.30756700000006px) 20.188175928px,
    calc(100% - 1.1368683772162e-13px) 24px,
    calc(100% - 0px) calc(100% - 46.035px),
    calc(100% - 0px) calc(100% - 46.035px),
    calc(100% - 0.29529899999989px) calc(100% - 42.30109px),
    calc(100% - 1.1512319999998px) calc(100% - 38.75144px),
    calc(100% - 2.5228530000001px) calc(100% - 35.43237px),
    calc(100% - 4.3652159999999px) calc(100% - 32.3902px),
    calc(100% - 6.6333750000001px) calc(100% - 29.67125px),
    calc(100% - 9.282384px) calc(100% - 27.32184px),
    calc(100% - 12.267297px) calc(100% - 25.38829px),
    calc(100% - 15.543168px) calc(100% - 23.91692px),
    calc(100% - 19.065051px) calc(100% - 22.95405px),
    calc(100% - 22.788px) calc(100% - 22.546px),
    24.7118px 317.751px,
    24.7118px 317.751px,
    20.8137551px 317.54869px,
    17.1051968px 316.7348px,
    13.6381397px 315.35979px,
    10.4645984px 313.47412px,
    7.6365875px 311.12825px,
    5.2061216px 308.37264px,
    3.2252153px 305.25775px,
    1.7458832px 301.83404px,
    0.8201399px 298.15197px,
    0.5px 294.262px,
    0.5px 24px
  );
}

.tab-highlight {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transition: transform 0.3s ease-out;
  min-width: max-content;
}

.tab-highlight::before {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  background-color: #00cc8f;
  border-radius: 8px;
}

.sk-cube-grid {
  width: 60px;
  height: 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
}

.sk-cube-grid .sk-cube {
  width: 100%;
  height: 100%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  border-radius: 2px;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  background-color: #fff;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  background-color: #00cc8f;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  background-color: #00cc8f;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  background-color: #fff;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  background-color: #fff;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  background-color: #00cc8f;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.bar-mask {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-polygon-0 {
  width: 100%;
  height: 127.731px;
  clip-path: polygon(
    0px 118.161px,
    0px 118.161px,
    0.16693794px 119.951517px,
    0.64652112px 121.625016px,
    1.40690358px 123.155919px,
    2.41623936px 124.518648px,
    3.6426825px 125.687625px,
    5.05438704px 126.637272px,
    6.61950702px 127.342011px,
    8.30619648px 127.776264px,
    10.08260946px 127.914453px,
    11.9169px 127.731px,
    calc(100% - 8.083px) calc(100% - 26.66px),
    calc(100% - 8.083px) calc(100% - 26.66px),
    calc(100% - 6.715791px) calc(100% - 27.0195032px),
    calc(100% - 5.439488px) calc(100% - 27.5511656px),
    calc(100% - 4.266577px) calc(100% - 28.2402164px),
    calc(100% - 3.209544px) calc(100% - 29.0718848px),
    calc(100% - 2.280875px) calc(100% - 30.0314px),
    calc(100% - 1.493056px) calc(100% - 31.1039912px),
    calc(100% - 0.85857300000001px) calc(100% - 32.2748876px),
    calc(100% - 0.38991200000001px) calc(100% - 33.5293184px),
    calc(100% - 0.099558999999999px) calc(100% - 34.8525128px),
    calc(100% - 2.8421709430404e-14px) calc(100% - 36.2297px),
    calc(100% - 0px) calc(100% - 117.981px),
    calc(100% - 0px) calc(100% - 117.981px),
    calc(100% - 0.13087899999999px) calc(100% - 119.56250154px),
    calc(100% - 0.509792px) calc(100% - 121.06275552px),
    calc(100% - 1.116153px) calc(100% - 122.46168798px),
    calc(100% - 1.929376px) calc(100% - 123.73922496px),
    calc(100% - 2.928875px) calc(100% - 124.8752925px),
    calc(100% - 4.094064px) calc(100% - 125.84981664px),
    calc(100% - 5.404357px) calc(100% - 126.64272342px),
    calc(100% - 6.839168px) calc(100% - 127.23393888px),
    calc(100% - 8.377911px) calc(100% - 127.60338906px),
    calc(100% - 10px) calc(100% - 127.731px),
    10px 0px,
    10px 0px,
    8.37794745px 0.12761094px,
    6.8392256px 0.49706112px,
    5.40442315px 1.08827658px,
    4.0941288px 1.88118336px,
    2.92893125px 2.8557075px,
    1.9294192px 3.99177504px,
    1.11618135px 5.26931202px,
    0.5098064px 6.66824448px,
    0.13088305px 8.16849846px,
    1.6555540320986e-31px 9.75px,
    0px 118.161px
  );
}

.mobile-polygon-1 {
  width: 100%;
  height: 127.731px;
  clip-path: polygon(
    calc(100% - 0px) calc(100% - 9.57px),
    calc(100% - 0px) calc(100% - 9.57px),
    calc(100% - 0.16695099999998px) calc(100% - 7.779483px),
    calc(100% - 0.646568px) calc(100% - 6.105984px),
    calc(100% - 1.406997px) calc(100% - 4.575081px),
    calc(100% - 2.416384px) calc(100% - 3.212352px),
    calc(100% - 3.642875px) calc(100% - 2.043375px),
    calc(100% - 5.054616px) calc(100% - 1.093728px),
    calc(100% - 6.619753px) calc(100% - 0.388989px),
    calc(100% - 8.306432px) calc(100% - -0.045264000000003px),
    calc(100% - 10.082799px) calc(100% - -0.18345299999999px),
    calc(100% - 11.917px) calc(100% - 0px),
    8.0831px 101.071px,
    8.0831px 101.071px,
    6.71587119px 100.7114968px,
    5.43955072px 100.1798344px,
    4.26662453px 99.4907836px,
    3.20957856px 98.6591152px,
    2.28089875px 97.6996px,
    1.49307104px 96.6270088px,
    0.85858137px 95.4561124px,
    0.38991568px 94.2016816px,
    0.09955991px 92.8784872px,
    1.2528208184606e-31px 91.5013px,
    0px 9.75px,
    0px 9.75px,
    0.13088305px 8.16849846px,
    0.5098064px 6.66824448px,
    1.11618135px 5.26931202px,
    1.9294192px 3.99177504px,
    2.92893125px 2.8557075px,
    4.0941288px 1.88118336px,
    5.40442315px 1.08827658px,
    6.8392256px 0.49706112px,
    8.37794745px 0.12761094px,
    10px 1.6141647190729e-31px,
    calc(100% - 10px) 0px,
    calc(100% - 10px) 0px,
    calc(100% - 8.377911px) 0.12761094px,
    calc(100% - 6.839168px) 0.49706112px,
    calc(100% - 5.404357px) 1.08827658px,
    calc(100% - 4.094064px) 1.88118336px,
    calc(100% - 2.928875px) 2.8557075px,
    calc(100% - 1.929376px) 3.99177504px,
    calc(100% - 1.116153px) 5.26931202px,
    calc(100% - 0.509792px) 6.66824448px,
    calc(100% - 0.13087899999999px) 8.16849846px,
    calc(100% - 2.8421709430404e-14px) 9.75px,
    calc(100% - 0px) calc(100% - 9.57px)
  );
}

.mobile-polygon-3 {
  width: 100%;
  height: 130.45px;
  clip-path: polygon(
    0px 12.6858px,
    0px 12.6858px,
    0.16843967px 10.84099506px,
    0.65218816px 9.11785368px,
    1.41888969px 7.54282002px,
    2.43618848px 6.14233824px,
    3.67172875px 4.9428525px,
    5.09315472px 3.97080696px,
    6.66811061px 3.25264578px,
    8.36424064px 2.81481312px,
    10.14918903px 2.68375314px,
    11.9906px 2.88591px,
    calc(100% - 8.009px) 31.3234px,
    calc(100% - 8.009px) 31.3234px,
    calc(100% - 6.652368px) 31.6994225px,
    calc(100% - 5.386624px) 32.249556px,
    calc(100% - 4.223996px) 32.9588395px,
    calc(100% - 3.176712px) 33.812312px,
    calc(100% - 2.257px) 34.7950125px,
    calc(100% - 1.477088px) 35.89198px,
    calc(100% - 0.84920399999999px) 37.0882535px,
    calc(100% - 0.38557600000001px) 38.368872px,
    calc(100% - 0.098431999999974px) 39.7188745px,
    calc(100% - 2.8421709430404e-14px) 41.1233px,
    calc(100% - 0px) calc(100% - 10px),
    calc(100% - 0px) calc(100% - 10px),
    calc(100% - 0.13087899999999px) calc(100% - 8.377911px),
    calc(100% - 0.509792px) calc(100% - 6.839168px),
    calc(100% - 1.116153px) calc(100% - 5.404357px),
    calc(100% - 1.929376px) calc(100% - 4.094064px),
    calc(100% - 2.928875px) calc(100% - 2.928875px),
    calc(100% - 4.094064px) calc(100% - 1.929376px),
    calc(100% - 5.404357px) calc(100% - 1.116153px),
    calc(100% - 6.839168px) calc(100% - 0.509792px),
    calc(100% - 8.377911px) calc(100% - 0.13087899999999px),
    calc(100% - 10px) calc(100% - 2.8421709430404e-14px),
    10px 130.45px,
    10px 130.45px,
    8.37794745px 130.319121px,
    6.8392256px 129.940208px,
    5.40442315px 129.333847px,
    4.0941288px 128.520624px,
    2.92893125px 127.521125px,
    1.9294192px 126.355936px,
    1.11618135px 125.045643px,
    0.5098064px 123.610832px,
    0.13088305px 122.072089px,
    1.6555540320986e-31px 120.45px,
    0px 12.6858px
  );
}

.mobile-polygon-2 {
  width: 100%;
  height: 130.45px;
  clip-path: polygon(
    calc(100% - 0px) 12.6858px,
    calc(100% - 0px) 12.6858px,
    calc(100% - 0.16842899999997px) 10.84099506px,
    calc(100% - 0.652152px) 9.11785368px,
    calc(100% - 1.418823px) 7.54282002px,
    calc(100% - 2.436096px) 6.14233824px,
    calc(100% - 3.671625px) 4.9428525px,
    calc(100% - 5.093064px) 3.97080696px,
    calc(100% - 6.668067px) 3.25264578px,
    calc(100% - 8.364288px) 2.81481312px,
    calc(100% - 10.149381px) 2.68375314px,
    calc(100% - 11.991px) 2.88591px,
    8.00938px 31.3234px,
    8.00938px 31.3234px,
    6.65265231px 31.6994225px,
    5.38683008px 32.249556px,
    4.22413957px 32.9588395px,
    3.17680704px 33.812312px,
    2.25705875px 34.7950125px,
    1.47712096px 35.89198px,
    0.84921993px 37.0882535px,
    0.38558192px 38.368872px,
    0.09843319px 39.7188745px,
    1.238399455037e-31px 41.1233px,
    0px 120.45px,
    0px 120.45px,
    0.13088332px 122.072089px,
    0.50980736px 123.610832px,
    1.11618324px 125.045643px,
    1.92942208px 126.355936px,
    2.928935px 127.521125px,
    4.09413312px 128.520624px,
    5.40442756px 129.333847px,
    6.83922944px 129.940208px,
    8.37794988px 130.319121px,
    10px 130.45px,
    calc(100% - 10px) calc(100% - 0px),
    calc(100% - 10px) calc(100% - 0px),
    calc(100% - 8.377911px) calc(100% - 0.13087899999999px),
    calc(100% - 6.839168px) calc(100% - 0.50979199999995px),
    calc(100% - 5.404357px) calc(100% - 1.116153px),
    calc(100% - 4.094064px) calc(100% - 1.929376px),
    calc(100% - 2.928875px) calc(100% - 2.928875px),
    calc(100% - 1.929376px) calc(100% - 4.094064px),
    calc(100% - 1.116153px) calc(100% - 5.404357px),
    calc(100% - 0.509792px) calc(100% - 6.839168px),
    calc(100% - 0.13087899999999px) calc(100% - 8.377911px),
    calc(100% - 2.8421709430404e-14px) calc(100% - 10px),
    calc(100% - 0px) 12.6858px
  );
}

.serp-list {
  width: 100% !important;
}

.delivery-wrapper a {
  color: #00cc8f;
}

.delivery-wrapper p {
  margin: 6px 0;
}

.delivery-wrapper table {
  width: 100%;
  margin: 12px 0;
}

.delivery-wrapper table td {
  text-align: center;
  margin: 4px 0;
}

.delivery-wrapper img {
  margin: 12px auto;
}

.icon-filter {
  filter: invert(49%) sepia(82%) saturate(1078%) hue-rotate(123deg) brightness(99%) contrast(101%);
}
